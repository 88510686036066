import React from 'react'
import NumberFormat from 'react-number-format'

export default function RenderPrice(props) {
	const { currency, value } = props
	switch (currency) {
		case 'RD':
			return (
				<NumberFormat
					prefix={`RD$ `}
					thousandSeparator={true}
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
					suffix={props.suffix}
					id={props.id}
				/>
			)
		case 'DOP':
			return (
				<NumberFormat
					prefix={`RD$ `}
					thousandSeparator={true}
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
					suffix={props.suffix}
					id={props.id}
				/>
			)
		case 'US':
			return (
				<NumberFormat
					prefix={`US$ `}
					thousandSeparator={true}
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
					suffix={props.suffix}
					id={props.id}
				/>
			)
		case 'USD':
			return (
				<NumberFormat
					prefix={`US$ `}
					thousandSeparator={true}
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
					suffix={props.suffix}
					id={props.id}
				/>
			)
		case 'MXN':
			return (
				<NumberFormat
					prefix="$"
					suffix=" MXN"
					thousandSeparator={true}
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
					id={props.id}
				/>
			)
		case 'COP':
			return (
				<NumberFormat
					prefix="$"
					thousandSeparator="."
					decimalSeparator=","
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
					suffix={props.suffix}
					id={props.id}
				/>
			)
		case 'CRC':
			return (
				<NumberFormat
					prefix="$"
					suffix=" CRC"
					thousandSeparator="."
					decimalSeparator=","
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
					suffix={props.suffix}
					id={props.id}
				/>
			)
		case 'GTQ':
			return (
				<NumberFormat
					prefix="Q "
					suffix=""
					thousandSeparator="."
					decimalSeparator=","
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
				/>
			)
		default:
			return (
				<NumberFormat
					prefix={`$ `}
					thousandSeparator={true}
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
					suffix={props.suffix}
					id={props.id}
				/>
			)
	}
}
