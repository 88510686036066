import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import './App.css'
import { CalculateUSD, CalculateMXN } from './components/plans'
import PriceRange from './components/PriceRange'

function App(props) {
	const [Step, setStep] = useState([1])
	const [period, setPeriod] = useState('monthly')
	const [data, setData] = useState({
		amount: 49,
		currency: 'USD',
		country: 'DOM',
	})

	useEffect(() => {
		RetrieveCountry()
	}, [])

	const RetrieveCountry = async () => {
		let newData = Object.assign({}, data)
		try {
			let res = await axios.get('https://ipapi.co/json/')
			console.log(res.data)
			switch (res.data.currency) {
				case 'MXN':
					newData.currency = 'MXN'
					newData.amount = CalculateMXN(Step[0])
					break
				default:
					newData.currency = 'USD'
					newData.amount = CalculateUSD(Step[0])
					break
			}
			newData.country = res.data.country_code_iso3
			setData(newData)
		} catch (error) {
			console.log(error)
		}
	}
	const handleChange = (values) => {
		let newData = Object.assign({}, data)
		switch (newData.currency) {
			case 'MXN':
				newData.currency = 'MXN'
				newData.amount = CalculateMXN(values[0])
				break
			default:
				newData.currency = 'USD'
				newData.amount = CalculateUSD(values[0])
				break
		}
		setStep(values)
		setData(newData)
		return
	}

	return (
		<Container>
			<div id="frequency">
				<Frequency className="btn-group">
					<button className={period === 'monthly' ? 'active' : ''} onClick={() => setPeriod('monthly')}>
						Pago mensual
					</button>
					<button className={period === 'yearly' ? 'active' : ''} onClick={() => setPeriod('yearly')}>
						Pago anual
						<div className="discount">Ahorra 20%</div>
					</button>
				</Frequency>
			</div>
			<PriceRange Step={Step} handleChange={handleChange} data={data} period={period} />
			{Step[0] === 30 && (
				<AlertMaxUsers>
					¿Necesitas más de 30 usuarios? Contáctanos, tenemos un precio especial para ti 😉
				</AlertMaxUsers>
			)}
			<div className="text-center low-opacity mt-4">
				Elige la cantidad de usuarios en tu empresa arrastrando la barra
			</div>
			<RegisterNow className="text-center">
				<a href={`https://app.alterestate.com/registro?user_amount=${Step[0]}&frequency=${period}`}>
					¡Registrate Ahora!
				</a>
				{data.country !== 'DOM' && (
					<div className="low-opacity d-block" style={{ marginTop: '23px' }}>
						No necesitas tarjeta de crédito para empezar
					</div>
				)}
			</RegisterNow>
		</Container>
	)
}
const Container = styled.div`
	padding: 30px;
	padding-top: 0px;
	max-width: 800px;
	margin: 20px auto;
	color: #0b345f;
`
const Frequency = styled.div`
	margin-bottom: 60px;
	display: flex;
	justify-content: center;
	button {
		font-size: 1.2rem;
		position: relative;
	}
	button.active {
		background-color: #00b7ba;
	}
	.discount {
		background-color: #ffdf5c;
		color: #192435;
		font-weight: bold;
		font-size: 14px;
		padding: 0.2em 0.8em;
		border-radius: 5px;
		position: absolute;
		min-width: 100px;
		top: -34px;
		left: 15px;
		border-radius: 20px;
	}
`
const RegisterNow = styled.div`
	margin-top: 70px;
	a {
		background: #00bd45;
		color: #fff;
		text-decoration: none;
		padding: 18px 25px;
		border-radius: 5px;
		font-size: 1.3rem;
		transition: all 0.3s;
		:hover {
			opacity: 0.8;
		}
	}
`
const AlertMaxUsers = styled.div`
	background: white;
	max-width: 700px;
	border-radius: 4px;
	margin: 0 auto;
	text-align: center;
	padding: 10px 5px;
	border: 1px solid #01b7b9;
	font-size: 1rem;
	margin-top: 15px;
`
export default App
