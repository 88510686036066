import React from 'react'
import styled from 'styled-components'
import { Range, getTrackBackground } from 'react-range'
import RenderPrice from './RenderPrice'

function PriceRange(props) {
	const { Step, data, handleChange, period } = props
	const MIN = 1
	const MAX = 30
	let anual_price = data.amount - data.amount * 0.2
	return (
		<Container>
			<div id="pricing">
				Contrata <span id="value">{Step[0]}</span>{' '}
				<span id="userText">{Step[0] > 1 ? 'usuarios' : 'usuario'}</span> por{' '}
				<span className="price_and_period">
					<RenderPrice
						id="value"
						currency={data.currency}
						value={period === 'yearly' ? anual_price.toFixed(0) : data.amount}
					/>{' '}
					al mes
				</span>
			</div>
			{period === 'yearly' && (
				<AnnualContent>
					Pago único de{' '}
					<RenderPrice id="value" currency={data.currency} value={(anual_price * 12).toFixed(2)} /> al año,{' '}
					<span className="price_and_period">
						te ahorras <RenderPrice currency={data.currency} value={(data.amount * 0.2 * 12).toFixed(0)} />
					</span>
				</AnnualContent>
			)}
			<Range
				step={1}
				min={MIN}
				max={MAX}
				values={Step}
				onChange={handleChange}
				renderTrack={({ props, children }) => (
					<div
						onMouseDown={props.onMouseDown}
						onTouchStart={props.onTouchStart}
						style={{
							...props.style,
							height: '36px',
							display: 'flex',
							width: '100%',
						}}>
						<div
							ref={props.ref}
							style={{
								height: '9px',
								width: '100%',
								borderRadius: '4px',
								background: getTrackBackground({
									values: Step,
									colors: ['#00b7ba', '#dedede'],
									min: MIN,
									max: MAX,
								}),
								alignSelf: 'center',
							}}>
							{children}
						</div>
					</div>
				)}
				renderThumb={({ props }) => <DraggerThumb {...props} />}
			/>
		</Container>
	)
}
const Container = styled.div`
	#pricing {
		text-align: center;
		font-weight: 400;
		font-size: 1.8rem;
		margin-bottom: 15px;
		@media only screen and (max-width: 600px) {
			font-size: 1.5rem;
		}
	}
	#value {
		font-weight: 700;
	}
	@media only screen and (max-width: 500px) {
		.price_and_period {
			display: block;
		}
	}
`
const AnnualContent = styled.div`
	text-align: center;
	margin-bottom: 20px;
`
const DraggerThumb = styled.div`
	height: 30px;
	width: 30px;
	background-color: #fff;
	border-radius: 50%;
	border: 5px solid #00b7ba;
	position: relative;
	@media only screen and (max-width: 600px) {
		height: 25px;
		width: 25px;
		border: 4px solid #00b7ba;
	}
`
export default PriceRange
