const pricesPlansUSD = [
	49.0, 68.0, 78.0, 104.0, 129.0, 144.0, 161.0, 176.0, 189.0, 210.0, 220.0, 240.0, 247.0, 266.0, 285.0, 288.0, 306.0,
	324.0, 342.0, 360.0, 367.5, 385.0, 391.0, 408.0, 425.0, 416.0, 432.0, 448.0, 464.0, 465.0,
]
const pricesPlansMXN = [
	699.0, 1258.0, 1617.0, 1968.0, 2245.0, 2514.0, 2793.0, 3032.0, 3321.0, 3590.0, 3784.0, 3984.0, 4173.0, 4382.0,
	4575.0, 4784.0, 4964.0, 5166.0, 5358.0, 5380.0, 5544.0, 5698.0, 5727.0, 5976.0, 6200.0, 6214.0, 6453.0, 6692.0,
	6844.0, 7080.0,
]
export const CalculateUSD = (amountUsers) => {
	let amount = 49.0
	amount = pricesPlansUSD[amountUsers - 1]
	return amount
}
export const CalculateMXN = (amountUsers) => {
	let amount = 699.0
	amount = pricesPlansMXN[amountUsers - 1]
	return amount
}
